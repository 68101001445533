.TenderFilter {
    background-color: var(--secondary-bg-color);
    border-radius: var(--border-radius);
    padding: 20px 20px 4px 20px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
        padding: 10px;
        margin-bottom: 10px;
    }
    @media (max-width: 576px) {
        padding: 15px;
        margin-bottom: 10px;
    }
    .input {
        background-color: var(--primary-bg-color);
        color: var(--primary-text-color);
        height: 50px;
        outline: 1px solid var(--primary-border-color);
        border: none;
    }
    input::placeholder {
        color: var(--hidden-text-color);
        opacity: 1; /* Необязательно: убирает прозрачность placeholder */
    }

    .datePicker {
        display: block;
        background-color: var(--primary-bg-color);
        outline: 1px solid var(--primary-border-color);
        border: none;
        color: var(--primary-text-color);
        input::placeholder {
            color: var(--hidden-text-color);
            opacity: 1; /* Необязательно: убирает прозрачность placeholder */
        }
    }

    .summPicker {
        input::placeholder {
            color: var(--hidden-text-color);
            opacity: 1; /* Необязательно: убирает прозрачность placeholder */
        }
        display: block;
        width: 100%;
        background-color: var(--primary-bg-color);
        outline: 1px solid var(--primary-border-color);
        border: none;
        color: var(--primary-text-color);
    }
}

.datePicker_mobile {
    width: 100% !important;
    padding: 10px !important;
    box-sizing: border-box;
    position: relative;
}

.datePicker_mobile::before {
    content: attr(data-placeholder);
    color: #aaa;
    position: absolute;
    pointer-events: none;
    left: 10px;
}

.datePicker_mobile.hasValue::before {
    display: none;
}

.reset {
    margin-top: 2px;
}
