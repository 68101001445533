.TenderCard {
    background-color: var(--secondary-bg-color);
    padding: 20px;
    border-radius: var(--border-radius);
    .tender_number {
        color: var(--hidden-text-color);
        margin-bottom: 5px;
    }
    .tender_title {
        font-weight: 700;
        font-size: 18px;
        color: var(--primary-text-color);
        @media (max-width: 576px) {
            font: var(--font-m);
        }
    }
    .tender_description {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        @media (max-width: 576px) {
            font: var(--font-s);
            text-decoration: underline;
            font-style: italic;
        }
    }
    .sections {
        display: flex;
        justify-content: space-between;
        .section {
            display: flex;
            flex-direction: column;
            .tender_info {
                display: flex;
                flex-wrap: wrap;
            }
            .info_item {
                min-width: 300px;
                display: flex;
                align-items: center;

                gap: 5px;

                @media (max-width: 576px) {
                    font: var(--font-s);
                }

                .info_with_blue_text {
                    // font-weight: 600;
                    display: flex;
                }
                .summ {
                    font-weight: 700;
                }
            }
            .info_item_small {
                width: 240px;
            }
        }
        .link {
            display: flex;
            align-items: center;
            gap: 10px;

            a {
                font: var(--font-m);
                font-weight: 700;
                color: var(--a-link-color);
                border-bottom: 2px solid var(--a-link-color);
                @media (max-width: 576px) {
                    font: var(--font-s);
                    font-style: italic;
                }
            }
        }
    }

    .card_footer {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 30px;
        .tender_lot_name {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .show_all_lots {
            a {
                padding: 0;
                margin: 0;
                text-decoration: underline;
                font: var(--font-m);
            }
        }
    }
}

.blue_text {
    color: var(--tertiary-text-color);
}

.loading {
    background-color: var(--skeleton-bg-color);
}

.expired {
    background-color: rgb(245, 202, 173);
}
