.simplebar-track {
    background: transparent; /* Прозрачный фон для трека */
}

.simplebar-scrollbar::before {
    background-color: #eaecf0; /* Цвет бегунка */
}

/* Для вертикального скроллбара */
.simplebar-track.simplebar-vertical {
    right: -20px; /* Сдвиг вправо */
}

/* Для горизонтального скроллбара */
.simplebar-track.simplebar-horizontal {
    bottom: 10px; /* Сдвиг вниз */
}

/* Дополнительные стили для настройки размера и других параметров */
.simplebar-scrollbar {
    width: 10px; /* Ширина вертикального бегунка */
    height: 10px; /* Высота горизонтального бегунка */
}
