.TenderStatistics {
    background-color: var(--tertiary-bg-color);
    margin-bottom: 25px;
    padding: 5px;
    @media (max-width: 1600px) {
        margin-bottom: 20px;
    }
    @media (max-width: 1200px) {
        margin-bottom: 15px;
    }
    @media (max-width: 992px) {
        margin-bottom: 10px;
    }
    @media (max-width: 768px) {
        margin-bottom: 5px;
    }
    @media (max-width: 576px) {
        margin-bottom: 3px;
    }

    .stat_card {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .title {
            // border: 1px solid red;
            font: var(--font-xl);
            color: var(--tertiary-text-color);
            font-weight: 700;

            @media (max-width: 1600px) {
                font: var(--font-l);
            }
            @media (max-width: 1200px) {
                font: var(--font-m);
            }
        }
        .title_small {
            // border: 1px solid red;
            font: var(--font-m);
            color: var(--tertiary-text-color);
            font-weight: 600;
            @media (max-width: 1600px) {
                font: var(--font-m);
            }
            @media (max-width: 1200px) {
                font: var(--font-s);
            }
        }
        .text {
            margin-left: 10px;
            word-wrap: break-word;

            @media (max-width: 1600px) {
                font: var(--font-s);
            }
            @media (max-width: 1200px) {
                font: var(--font-s);
            }
        }
    }
}

.bold {
    font-weight: 700;
}
