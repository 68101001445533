.featuresSection {
    text-align: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 10px auto;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.subtitle {
    font-size: 1rem;
    color: #666;
    margin-bottom: 40px;
    line-height: 1.5;
}
.featuresGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 25px;
    }
}

.featureItem {
    max-width: 400px;
    flex: 0 1 calc(50% - 20px); // Две карточки в ряд с учетом gap: 40px
    text-align: left;
    display: flex;
    align-items: flex-start;
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 20px;
    box-sizing: border-box;

    @media (max-width: 768px) {
        flex: 1 1 100%;
    }
}

.icon {
    margin-top: 4px;

    svg {
        width: 32px;
        height: 32px;
    }
}

.block {
    h3 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 10px 0;
        line-height: 1.4;
    }

    p {
        font-size: 0.9rem;
        color: #555;
        line-height: 1.4;
        margin: 0;
    }
}
