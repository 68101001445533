.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
}

.errorCode {
    font-size: 6rem;
    margin: 0;
    color: #333;
}

.errorMessage {
    font-size: 1.5rem;
    color: #666;
    margin: 20px 0;
}

.refreshButton {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.refreshButton:hover {
    background-color: #0056b3;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 600px) {
    .errorCode {
        font-size: 4rem;
    }

    .errorMessage {
        font-size: 1.2rem;
    }

    .refreshButton {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
