.userProfile {
    background-color: var(--primary-bg-color);
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 480px) {
        padding: 10px;
    }
}

.userInfo {
    margin-bottom: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .avatar {
        margin-right: 20px;

        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            object-fit: cover;

            @media (max-width: 768px) {
                width: 120px;
                height: 120px;
                margin-bottom: 10px;
            }

            @media (max-width: 480px) {
                width: 100px;
                height: 100px;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 5px;
        margin-bottom: 20px;
    }

    .details {
        .name {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .plan,
        .date,
        .theme {
            font-size: 16px;
            color: var(--primary-text-color);
            margin-bottom: 5px;

            @media (max-width: 480px) {
                font-size: 14px;
            }
        }
    }

    .themeSwitcher {
        margin-left: auto;

        @media (max-width: 768px) {
            margin-left: 0;
            margin-top: 10px;
        }
    }
}
.tags {
    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }
}

.availablePages {
    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            background-color: var(--secondary-bg-color);
            margin-bottom: 10px;
            padding: 10px 15px;
            border-radius: 5px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

            @media (max-width: 480px) {
                padding: 8px 12px;
            }
        }
    }
}
