.app_light_theme {
    --primary-bg-color: #f4f7fa;
    --secondary-bg-color: #ffffff;
    // --tertiary-bg-color: #eaf1ff;
    --tertiary-bg-color: #eaf1ff;

    --primary-border-color: #cdcfd2;

    --primary-text-color: #1f2129;
    --secondary-text-color: #ffffff;
    --tertiary-text-color: #0145d4;

    --normal-btn-color: #5b7ee7;
    --success-btn-color: rgb(103, 201, 103);

    --a-link-color: #5a80ff;

    --hidden-text-color: #808080;

    --primary-border-color: #e0e0e0;

    --error-bg-color: rgb(236, 56, 56);
}
