.Button {
    cursor: pointer;
    color: var(--primary-text-color);
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

// Variants

.link {
    color: var(--a-link-color);
    padding: 0;
    border: none;
    background: none;
    outline: none;
    text-decoration: underline;
}
.clear {
    padding: 0;
    border: none;
    background: none;
    outline: none;
}

.filled {
    background: var(--normal-btn-color);
    border: none;
    outline: none;
    border-radius: var(--border-radius-btn);
    color: var(--secondary-text-color);
}

.outline {
    background: none;
    border-radius: var(--border-radius-btn);

    &.normal {
        border: 1px solid var(--normal-btn-color);
    }

    &.success {
        border: 1px solid var(--success-btn-color);
    }

    &.error {
        border: 1px solid var(--error-btn-color);
        background-color: var(--error-bg-color);
        color: var(--secondary-text-color);
    }
}

.square {
    padding: 0;
    width: var(--btn-width);
    height: var(--btn-height);
}

.s {
    font: var(--font-s);
}

.m {
    font: var(--font-m);
}

.l {
    font: var(--font-l);
}

.xl {
    font: var(--font-xl);
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.fullWidth {
    width: 100%;
}

.fullHeight {
    height: 100%;
}

.withAddon {
    padding: 0 16px;
}

.addonLeft,
.addonRight {
    display: flex;
    align-items: center;
}
