.mobile_nav {
    background: var(--secondary-bg-color);
    height: 0;
    overflow: hidden;
    transition: 0.6s;
}

.active {
    height: calc(100svh + 106px);
    padding: 70px 16px 16px 16px;
    width: 100%;
    z-index: 998;
    position: fixed !important;
}
