.MainPageAlert {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 18px;
    @media (max-width: 1600px) {
        margin-bottom: 20px;
    }
    @media (max-width: 1200px) {
        margin-bottom: 10px;
    }
    @media (max-width: 992px) {
        margin-bottom: 8px;
    }
    @media (max-width: 768px) {
        margin-bottom: 5px;
    }
    @media (max-width: 576px) {
        margin-bottom: 3px;
    }

    .title {
        text-align: center;
        margin-top: 20px;
        font: var(--font-s);
        color: var(--hidden-text-color);
        font-weight: 700;
        @media (max-width: 1600px) {
            margin-top: 20px;
        }
        @media (max-width: 1200px) {
            margin-top: 20px;
        }
        @media (max-width: 992px) {
            margin-top: 20px;
        }
        @media (max-width: 768px) {
            margin-top: 20px;
        }
        @media (max-width: 576px) {
            margin-top: 3px;
        }
    }

    .text {
        text-align: center;
        margin-top: 10px;
        font-size: 60px;
        font-weight: 700;
        line-height: 70px;
        @media (max-width: 1600px) {
            font-size: 35px;
            font-weight: 700;
            margin-top: 10px;
            line-height: 35px;
        }
        @media (max-width: 1200px) {
            font-size: 30px;
            font-weight: 700;
            margin-top: 5px;
            line-height: 30px;
        }
        @media (max-width: 992px) {
            font-size: 30px;
            font-weight: 700;
            margin-top: 16px;
            line-height: 30px;
        }
        @media (max-width: 768px) {
            font-size: 20px;
            font-weight: 700;
            margin-top: 10px;
            line-height: 20px;
        }
        @media (max-width: 576px) {
            font-size: 20px;
            font-weight: 700;
            margin-top: 5px;
            line-height: 20px;
        }
    }
}
