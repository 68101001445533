@import 'reset';
@import 'variables/global';
@import 'themes/normal';
@import 'themes/dark';

.app,
.app_modal,
.app_drawer {
    font-family: 'Giltroy';
    font: var(--font-m);
    color: var(--primary-text-color);
}

.app {
    background-color: var(--primary-bg-color);
    min-height: 100vh;
}
*::-webkit-scrollbar {
    width: 12px; /* ширина scrollbar */
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: ''; /* цвет дорожки */
}

*::-webkit-scrollbar-thumb {
    background-color: #d8d8da; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
    border: none; /* padding вокруг плашки */
}

.app *::-webkit-scrollbar {
    width: 10px; /* ширина scrollbar */
    height: 8px;
}

.app *::-webkit-scrollbar-track {
    background: ''; /* цвет дорожки */
}

.app *::-webkit-scrollbar-thumb {
    background-color: #d8d8da; /* цвет плашки */
    border-radius: 20px; /* закругления плашки */
}

.highlight {
    background-color: yellow;
}

.vibrate {
    animation: vibrate 1s linear infinite;
}

@keyframes vibrate {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 2px);
    }
    40% {
        transform: translate(-2px, -2px);
    }
    60% {
        transform: translate(2px, 2px);
    }
    80% {
        transform: translate(2px, -2px);
    }
    100% {
        transform: translate(0);
    }
}
