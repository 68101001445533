.Navbar {
    z-index: 999 !important;
    background-color: var(--primary-bg-color);
    padding: 15px 0 10px 0;
    width: 100%;
    border-bottom: 1px solid var(--primary-border-color);
    position: fixed !important;
    @media (max-width: 1600px) {
        padding: 18px 0 18px 0;
    }
    @media (max-width: 1200px) {
        padding: 15px 0 15px 0;
    }
    @media (max-width: 992px) {
        padding: 10px 0 10px 0;
    }
    @media (max-width: 768px) {
        padding: 8px 0 8px 0;
    }
    @media (max-width: 576px) {
        padding: 5px 0 5px 0;
    }

    .logo_text {
        font: var(--font-l);
        color: var(--primary-text-color);
        font-weight: 700;

        @media (max-width: 768px) {
            display: none;
        }
    }
    .links {
        list-style-type: none;
        padding: 0;
        cursor: pointer;
        margin: 0;
        font: var(--font-m);
        display: flex;

        li {
            display: inline;
            margin-right: 10px;
        }
    }
    @media (max-width: 768px) {
        /* Для экранов с шириной 768px или меньше */
        .links {
            font: var(--font-s); /* Применяем другой шрифт */
        }
    }
}

.col {
    display: flex;
    align-items: center;
}
