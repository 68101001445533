.select {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    color: var(--primary-text-color);
    background-color: var(--primary-bg-color);
}

.icon {
    margin-right: 5px;
}
