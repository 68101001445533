.app_dark_theme {
    --primary-bg-color: #3a3a3a;
    --secondary-bg-color: #646464;
    --tertiary-bg-color: #646464;

    --primary-border-color: #cdcfd2;

    --primary-text-color: #d8d8d8;
    --secondary-text-color: #ffffff;
    --tertiary-text-color: black;

    --normal-btn-color: #4e4e4e;
    --success-btn-color: rgb(103, 201, 103);

    --a-link-color: #3a3a3a;

    --hidden-text-color: #d8d8d8;

    --primary-border-color: #646464;

    --error-bg-color: rgb(196, 91, 91);
}
