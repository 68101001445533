.page_wrapper {
    padding-top: 70px;
    @media (max-width: 1600px) {
        padding-top: 70px;
    }
    @media (max-width: 1200px) {
        padding-top: 70px;
    }
    @media (max-width: 992px) {
        padding-top: 70px;
    }
    @media (max-width: 768px) {
        padding-top: 70px;
    }
    @media (max-width: 576px) {
        padding-top: 70px;
    }
}
